
import { getChannelsRecord } from '@/services/api';
import { defineComponent, ref, toRefs, onMounted, watch, PropType } from 'vue';
import dayjs from 'dayjs';

const formatTime = (time: string) => {
  if (!time) return;

  const TIME_FORMAT = 'YYYY-MM-DD';
  return dayjs(time).format(TIME_FORMAT);
};

export default defineComponent({
  props: {
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    channelId: {
      type: [Number, String],
      required: true
      // default: null
    }
  },
  setup(props) {
    const { dateRange, channelId } = toRefs(props);
    const page = ref(1);
    const data = ref();

    const dateValues = ref(dateRange.value);
    watch(dateRange, (dateRange) => {
      (dateValues.value) = dateRange;
    });

    const fetchChannelsRecord = async() => {
      if (channelId.value) {
        const res = await getChannelsRecord(
          {
            channelId: `${channelId.value}`,
            query: {
              page: page.value,
              ...(dateRange.value && {
                date: `${dateRange.value[0]} ${dateRange.value[1]}`
              })
            }
          });
        data.value = res;
      }
    };

    watch([channelId, dateRange, page], () => {
      fetchChannelsRecord();
    });

    onMounted(() => {
      fetchChannelsRecord();
    });

    const handlePageChange = (newPage: number) => {
      /**
       * @see {@link https://github.com/element-plus/element-plus/issues/1161}
       */
      if (!newPage) return;
      page.value = newPage;
    };

    return {
      page,
      handlePageChange,
      data,
      formatTime
    };
  }
});
